// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../../styleguide/components/Paragraph/P.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as IconPlus from "../../../../styleguide/icons/IconPlus.res.js";
import * as Visibility from "../../../../libs/Visibility.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProjectWizard from "../_components/ProjectWizard/ProjectWizard.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInModalContainer from "../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProjectWizardNavigation from "../_components/ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as CreateColocationRfpProjectLocationsScss from "./CreateColocationRfpProjectLocations.scss";
import * as CreateColocationRfpProjectLocations_Container from "./CreateColocationRfpProjectLocations_Container.res.js";
import * as CreateColocationRfpProjectLocations_CitiesList from "./CreateColocationRfpProjectLocations_CitiesList.res.js";
import * as CreateColocationRfpProjectLocations_MapWithLocations from "./CreateColocationRfpProjectLocations_MapWithLocations.res.js";

var css = CreateColocationRfpProjectLocationsScss;

function CreateColocationRfpProjectLocations(props) {
  var projectStatus = props.projectStatus;
  var mobile = props.mobile;
  var category = props.category;
  var projectId = props.projectId;
  var match = React.useState(function () {
        return "Hidden";
      });
  var toggleSignInModal = match[1];
  var signInModal = match[0];
  var data = CreateColocationRfpProjectLocations_Container.useCitiesAndLocations(props.cities, projectId, props.filters, props.locations, props.availableProviders, props.unavailableProviders, props.userLoginStatus);
  var goNextUrl = React.useMemo((function () {
          if (projectId !== undefined) {
            return Routes_Project.GeneralInfo.update(Caml_option.valFromOption(projectId));
          } else {
            return Routes_Project.GeneralInfo.$$new(category);
          }
        }), []);
  var goBackUrl = React.useMemo((function () {
          if (projectId !== undefined) {
            return Routes_Project.Colocation.Configuration.update(Caml_option.valFromOption(projectId));
          } else {
            return Routes_Project.Colocation.$$new;
          }
        }), []);
  var navigationPreviousControl_1 = function (param) {
    Url.visit(goBackUrl);
  };
  var navigationPreviousControl = [
    "Back",
    navigationPreviousControl_1,
    "Enabled"
  ];
  var navigationNextControl = React.useMemo((function () {
          return [
                  projectId !== undefined ? "Save and Next" : "Next",
                  (function () {
                      Url.visit(goNextUrl);
                    }),
                  data.cities.length === 0 ? "Disabled" : "Enabled"
                ];
        }), [
        projectId,
        data.cities
      ]);
  var navigationContinueLaterControl = React.useMemo((function () {
          var match = data.userLoginStatus;
          if (match === "LoggedIn" && projectStatus === "Draft") {
            return [
                    "Save and Continue Later",
                    (function () {
                        data.saveDraftProjectAndContinueLater();
                      })
                  ];
          }
          
        }), [data.userLoginStatus]);
  var tmp;
  if (data.isMapShown) {
    tmp = JsxRuntime.jsx(CreateColocationRfpProjectLocations_MapWithLocations.make, {
          serverSide: props.serverSide,
          certifications: props.certifications,
          providersForFilter: props.providersForFilter,
          data: data,
          goBackUrl: goBackUrl,
          mobile: mobile
        });
  } else {
    var tmp$1;
    tmp$1 = signInModal === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
            close: (function () {
                toggleSignInModal(function (param) {
                      return Visibility.toggle(signInModal);
                    });
              }),
            callback: (function () {
                data.toggleLoginStatus("LoggedIn");
              }),
            setUserData: props.setUserData,
            subTitle: "Sign In to save the project detail and continue later"
          }) : null;
    tmp = JsxRuntime.jsxs(ProjectWizard.Container.make, {
          children: [
            JsxRuntime.jsxs(ProjectWizard.Content.make, {
                  children: [
                    JsxRuntime.jsx(ProjectWizard.H1.make, {
                          children: projectId !== undefined ? "Edit Project" : "Step 2: Confirm Your Data Center Locations"
                        }),
                    JsxRuntime.jsx(ProjectWizard.H2.make, {
                          children: "You Can Manage Locations Here"
                        }),
                    JsxRuntime.jsx(P.make, {
                          children: "Click \"Add or Remove Locations\" to add/remove cities and data centers."
                        }),
                    JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx(CreateColocationRfpProjectLocations_CitiesList.make, {
                                cities: data.cities,
                                onEdit: (function (cityStateCountry) {
                                    var init = data.filters;
                                    data.setFilters({
                                          providerIds: init.providerIds,
                                          certificationIds: init.certificationIds,
                                          cities: [cityStateCountry],
                                          radius: init.radius,
                                          query: init.query
                                        });
                                    data.toggleMap();
                                  })
                              }),
                          className: css.listContainer
                        }),
                    JsxRuntime.jsxs(Button.make, {
                          size: "SM",
                          color: "Teal",
                          className: css.addLocation,
                          onClick: (function (param) {
                              data.toggleMap();
                            }),
                          children: [
                            JsxRuntime.jsx(IconPlus.make, {
                                  size: "SM",
                                  color: "White"
                                }),
                            "Add or Remove Locations"
                          ]
                        })
                  ]
                }),
            JsxRuntime.jsx(ProjectWizardNavigation.make, {
                  stepLabel: "Step 2 of 3",
                  errorMessage: data.errorMessage,
                  controls: {
                    TAG: "PreviousAndNextAndContinueLater",
                    next: navigationNextControl,
                    previous: navigationPreviousControl,
                    continueLater: navigationContinueLaterControl
                  },
                  mobile: mobile
                }),
            tmp$1
          ]
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                data.isLoading ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: css.spinnerMap
                      }) : null,
                tmp
              ]
            });
}

var make = CreateColocationRfpProjectLocations;

export {
  css ,
  make ,
}
/* css Not a pure module */
