// extracted by mini-css-extract-plugin
export var column = "AutocompleteCitiesField__column__DZ0Op";
export var flex = "AutocompleteCitiesField__flex__C6PXg";
export var flexColumn = "AutocompleteCitiesField__flexColumn__SEBpr";
export var gap1 = "AutocompleteCitiesField__gap1__zI2t6";
export var gap2 = "AutocompleteCitiesField__gap2__zBaSj";
export var gap3 = "AutocompleteCitiesField__gap3__qEeSV";
export var gap4 = "AutocompleteCitiesField__gap4__lbNgM";
export var gap5 = "AutocompleteCitiesField__gap5__WR_mA";
export var row = "AutocompleteCitiesField__row__cDL7y";
export var suggestionItem = "AutocompleteCitiesField__suggestionItem__VdX9B";
export var suggestionsContainer = "AutocompleteCitiesField__suggestionsContainer__urWMJ";
export var suggestionsList = "AutocompleteCitiesField__suggestionsList__m4cCy";