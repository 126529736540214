// extracted by mini-css-extract-plugin
export var addLocation = "CreateColocationRfpProjectLocations__addLocation__JsmQG";
export var buttons = "CreateColocationRfpProjectLocations__buttons__p7hqT";
export var column = "CreateColocationRfpProjectLocations__column__CP24a";
export var container = "CreateColocationRfpProjectLocations__container__p_Wtu";
export var contentWrapper = "CreateColocationRfpProjectLocations__contentWrapper__RE1Jx";
export var description = "CreateColocationRfpProjectLocations__description__QCvJA";
export var flex = "CreateColocationRfpProjectLocations__flex__CsRz8";
export var flexColumn = "CreateColocationRfpProjectLocations__flexColumn__LHxh8";
export var gap1 = "CreateColocationRfpProjectLocations__gap1__M0aVc";
export var gap2 = "CreateColocationRfpProjectLocations__gap2__idnCZ";
export var gap3 = "CreateColocationRfpProjectLocations__gap3__nNU_e";
export var gap4 = "CreateColocationRfpProjectLocations__gap4__D_Cca";
export var gap5 = "CreateColocationRfpProjectLocations__gap5__fEzvy";
export var listContainer = "CreateColocationRfpProjectLocations__listContainer__UH4xr";
export var mapContainer = "CreateColocationRfpProjectLocations__mapContainer__IZd30";
export var mapElement = "CreateColocationRfpProjectLocations__mapElement__UbjPM";
export var next = "CreateColocationRfpProjectLocations__next___Y4hf";
export var row = "CreateColocationRfpProjectLocations__row__mpvTy";
export var spinnerMap = "CreateColocationRfpProjectLocations__spinnerMap__uWP9G";
export var steps = "CreateColocationRfpProjectLocations__steps__HGW_8";