// extracted by mini-css-extract-plugin
export var column = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__column__IulRe";
export var flex = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__flex__mLf6B";
export var flexColumn = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__flexColumn__OUUqx";
export var gap1 = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__gap1__Posfx";
export var gap2 = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__gap2__n1KjL";
export var gap3 = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__gap3__vETfK";
export var gap4 = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__gap4__c265O";
export var gap5 = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__gap5__XTWM6";
export var locationCountDescription = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__locationCountDescription__K5ADS";
export var locationCountHeading = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__locationCountHeading__H4U1e";
export var locationsCount = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__locationsCount__m1wge";
export var row = "CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders__row__z6hcG";