// extracted by mini-css-extract-plugin
export var column = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__column__mqdZB";
export var flex = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__flex__V13I1";
export var flexColumn = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__flexColumn__fQJv0";
export var gap1 = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__gap1__gmzWY";
export var gap2 = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__gap2__m70zB";
export var gap3 = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__gap3__y_M4d";
export var gap4 = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__gap4__VhCOr";
export var gap5 = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__gap5__MVKDE";
export var locationCountDescription = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__locationCountDescription__b5vbk";
export var locationCountHeading = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__locationCountHeading__CvWZ3";
export var locationsCount = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__locationsCount__ov7D3";
export var noProviders = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__noProviders__MyokP";
export var noProvidersDescription = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__noProvidersDescription__g8p4Z";
export var noProvidersHeading = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__noProvidersHeading__nPkzZ";
export var row = "CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders__row__d6smG";