// extracted by mini-css-extract-plugin
export var column = "SignInError__column__IKOLz";
export var fieldError = "SignInError__fieldError__BC3vS";
export var flex = "SignInError__flex__Ly2P9";
export var flexColumn = "SignInError__flexColumn__vMm10";
export var gap1 = "SignInError__gap1__wLfxb";
export var gap2 = "SignInError__gap2__sx5nl";
export var gap3 = "SignInError__gap3__drggs";
export var gap4 = "SignInError__gap4__s9UAD";
export var gap5 = "SignInError__gap5__jdMzy";
export var row = "SignInError__row__I0M88";