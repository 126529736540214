// extracted by mini-css-extract-plugin
export var column = "SignUpModal__column__Mx9O5";
export var flex = "SignUpModal__flex__dgCvn";
export var flexColumn = "SignUpModal__flexColumn__v_xM_";
export var gap1 = "SignUpModal__gap1__sNKUg";
export var gap2 = "SignUpModal__gap2__hUQtu";
export var gap3 = "SignUpModal__gap3__Lrrb9";
export var gap4 = "SignUpModal__gap4__y1r6a";
export var gap5 = "SignUpModal__gap5__uAOr7";
export var row = "SignUpModal__row__RAMQj";
export var signUpContainer = "SignUpModal__signUpContainer__UCB37";