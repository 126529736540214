// extracted by mini-css-extract-plugin
export var column = "CreateColocationRfpProjectLocations_Map__column__xqGVm";
export var flex = "CreateColocationRfpProjectLocations_Map__flex__cUVbT";
export var flexColumn = "CreateColocationRfpProjectLocations_Map__flexColumn__QBltK";
export var gap1 = "CreateColocationRfpProjectLocations_Map__gap1__KxAxQ";
export var gap2 = "CreateColocationRfpProjectLocations_Map__gap2__HlNEE";
export var gap3 = "CreateColocationRfpProjectLocations_Map__gap3__oYbat";
export var gap4 = "CreateColocationRfpProjectLocations_Map__gap4__jz4es";
export var gap5 = "CreateColocationRfpProjectLocations_Map__gap5__ZCbqr";
export var mapContainer = "CreateColocationRfpProjectLocations_Map__mapContainer__irpFv";
export var mapElement = "CreateColocationRfpProjectLocations_Map__mapElement__UxikL";
export var row = "CreateColocationRfpProjectLocations_Map__row__jFmms";