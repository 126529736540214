// extracted by mini-css-extract-plugin
export var column = "CreateColocationRfpProjectLocations_CitiesList__column__XikNq";
export var description = "CreateColocationRfpProjectLocations_CitiesList__description__XIEom";
export var edit = "CreateColocationRfpProjectLocations_CitiesList__edit__oTh0N";
export var flex = "CreateColocationRfpProjectLocations_CitiesList__flex__hVs7u";
export var flexColumn = "CreateColocationRfpProjectLocations_CitiesList__flexColumn__Kwqu3";
export var gap1 = "CreateColocationRfpProjectLocations_CitiesList__gap1__pQuAv";
export var gap2 = "CreateColocationRfpProjectLocations_CitiesList__gap2__Mcoqy";
export var gap3 = "CreateColocationRfpProjectLocations_CitiesList__gap3__dTvRf";
export var gap4 = "CreateColocationRfpProjectLocations_CitiesList__gap4__GNPnr";
export var gap5 = "CreateColocationRfpProjectLocations_CitiesList__gap5__jGG1Z";
export var removeTile = "CreateColocationRfpProjectLocations_CitiesList__removeTile__xO2Ni";
export var row = "CreateColocationRfpProjectLocations_CitiesList__row__D8o5r";
export var tileContainer = "CreateColocationRfpProjectLocations_CitiesList__tileContainer__XPMl2";
export var tileInfo = "CreateColocationRfpProjectLocations_CitiesList__tileInfo__PRhX8";
export var tileInfoContainer = "CreateColocationRfpProjectLocations_CitiesList__tileInfoContainer__zUyup";
export var title = "CreateColocationRfpProjectLocations_CitiesList__title__uRdbX";